import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import Authenticator from 'components/middleware/authenticator';
import AddAddress from 'pages/charger/AddAddress';
import UserConsent from 'pages/privacy-policy/UserConsent';

const Home = Loadable(lazy(() => import('pages/home/Home')));
const MyAccount = Loadable(lazy(() => import('pages/my-account/MyAccount')));
const ChargingHistory = Loadable(lazy(() => import('pages/charging-history/ChargingHistory')));
const MyVehicles = Loadable(lazy(() => import('pages/my-vehicles/MyVehicles')));
const MyChargingCards = Loadable(lazy(() => import('pages/my-charging-cards/MyChargingCards')));
const MyUsage = Loadable(lazy(() => import('pages/my-usage/MyUsage')));
const WalletWrapper = Loadable(lazy(() => import('pages/wallet/WalletWrapper')));
const MyBookings = Loadable(lazy(() => import('pages/my-bookings/MyBookings')));
const HelpSupport = Loadable(lazy(() => import('pages/help-support/HelpSupport')));
const QRcodeScanner = Loadable(lazy(() => import('pages/qr-scanner/QRScanner')));
const StationList = Loadable(lazy(() => import('pages/list-stations/StationList')));
const ChargerDetails = Loadable(lazy(() => import('pages/charger/ChargerDetails')));
const OngoingChargingStatus = Loadable(lazy(() => import('pages/charger/OngoingChargingStatus')));
const PrivacyPolicy = Loadable(lazy(() => import('pages/privacy-policy/PrivacyPolicy')));
const TermsOfService = Loadable(lazy(() => import('pages/terms-of-service/TermsOfService')));
const OTPVerify = Loadable(lazy(() => import('pages/authentication/OTPVerify')));
const ContactUs = Loadable(lazy(() => import('pages/contact-us/ContactUs')));
const PaymentCharges = Loadable(lazy(() => import('pages/payment-charges/PaymentCharges')));
const CancellationRefunds = Loadable(lazy(() => import('pages/Cancellation-refund/CancellationRefunds')));
const AboutUs = Loadable(lazy(() => import('pages/about-us/AboutUs')));
const Facility = Loadable(lazy(() => import('pages/facility/Facility')));
const NotFound404 = Loadable(lazy(() => import('pages/404/NotFound')));
const ApplyCoupon = Loadable(lazy(() => import('pages/apply-coupon/ApplyCoupon')));
const PaymentSuccessfull = Loadable(lazy(() => import('pages/payment-successful/PaymentSuccessfull')));
const PaymentNotSuccessfull = Loadable(lazy(() => import('pages/payment-not-successful/PaymentNotSuccessfull')));


// ==============================|| MAIN ROUTING ||============================== //

const MyAccountRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: 'home',
      element: <Home />
    },
    {
      path: 'my-account',
      element: <Authenticator component={<MyAccount />} />
    },
    {
      path: 'charging-history',
      element: <Authenticator component={<ChargingHistory />} />
    },
    {
      path: 'my-vehicles',
      element: <Authenticator component={<MyVehicles />} />
    },
    {
      path: 'my-charging-cards',
      element: <Authenticator component={<MyChargingCards />} />
    },
    {
      path: 'my-usage',
      element: <Authenticator component={<MyUsage />} />
    },
    {
      path: 'wallet',
      element: <WalletWrapper />
    },
    {
      path: 'my-bookings',
      element: <Authenticator component={<MyBookings />} />
    },
    {
      path: 'help-support',
      element: <HelpSupport />
    },
    {
      path: 'qr-scanner',
      element: <QRcodeScanner />
    },
    {
      path: 'list-stations',
      element: <StationList />
    },
    {
      path: 'charger/:id',
      element: <Authenticator component={<ChargerDetails />} />
    },
    {
      path: 'charger-status/:id',
      element: <Authenticator component={<OngoingChargingStatus />} />
    },
    {
      path: 'charger-status/:id/add-address',
      element: <Authenticator component={<AddAddress />} />
    },
    {
      path: 'pdpa',
      element: <Authenticator component={<UserConsent />} />
    },
    {
      path: 'privacy-policy',
      element: <PrivacyPolicy />
    },
    {
      path: 'terms-of-service',
      element: <TermsOfService />
    },
    {
      path: 'contact-us',
      element: <ContactUs />
    },
    {
      path: 'payment-charges',
      element: <PaymentCharges />
    },
    {
      path: 'cancellation-refund',
      element: <CancellationRefunds />
    }, {
      path: 'about-us',
      element: <AboutUs />
    },
    {
      path: 'otp-verify',
      element: <Authenticator component={<OTPVerify />} />
    },
    {
      path: '*',
      element: <NotFound404 />
    },
    {
      path: 'facility/:id',
      element: <Facility />
    },
    {
      path: 'apply-coupon/:charger_id',
      element: <ApplyCoupon />
    }
  ]
};

export default MyAccountRoutes;
