import { Language } from 'language';
// assets
import {
  UserOutlined,
  HistoryOutlined,
  CarOutlined,
  IdcardOutlined,
  BarChartOutlined,
  CalendarOutlined,
  QuestionOutlined,
  HomeOutlined,
  WalletOutlined,
  LogoutOutlined,
  ContactsOutlined,
  BorderOuterOutlined
} from '@ant-design/icons';

// icons
const icons = {
  UserOutlined,
  HistoryOutlined,
  CarOutlined,
  IdcardOutlined,
  BarChartOutlined,
  CalendarOutlined,
  QuestionOutlined,
  HomeOutlined,
  WalletOutlined,
  LogoutOutlined,
  ContactsOutlined,
  BorderOuterOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //
const myAccount = {
  id: 'group-menu',
  title: '',
  type: 'group',
  children: [
    // {
    //   id: 'home',
    //   title: 'Home',
    //   type: 'item',
    //   url: '/home',
    //   icon: icons.HomeOutlined,
    //   breadcrumbs: false
    // },
    {
      id: 'myaccount',
      title: Language.heading.profile,
      type: 'item',
      url: '/my-account',
      icon: icons.UserOutlined,
      breadcrumbs: true
    },
    {
      id: 'wallet',
      title: Language.heading.wallet,
      type: 'item',
      url: '/wallet',
      icon: icons.WalletOutlined,
      breadcrumbs: true
    },
    {
      id: 'myVehicles',
      title: Language.heading.my_vehicles,
      type: 'item',
      url: '/my-vehicles',
      icon: icons.CarOutlined,
      breadcrumbs: true
    },
    {
      id: 'chargingHistory',
      title: Language.heading.charging_history,
      type: 'item',
      url: '/charging-history',
      icon: icons.HistoryOutlined,
      breadcrumbs: true
    },
    {
      id: 'privacyPolicy',
      title: Language.heading.privacy_policy,
      type: 'item',
      url: '/privacy-policy',
      icon: icons.BorderOuterOutlined,
      breadcrumbs: true
    },
    {
      id: 'contactUs',
      title: Language.heading.contact_us,
      type: 'item',
      url: '/contact-us',
      icon: icons.ContactsOutlined,
      breadcrumbs: true
    },
    {
      id: 'logout',
      title: Language.heading.logout,
      type: 'item-login',
      url: '/logout',
      icon: icons.LogoutOutlined,
      breadcrumbs: true,
    }
    
    // {
    //   id: 'MyChargingCards',
    //   title: 'My Charging Cards',
    //   type: 'item',
    //   url: '/my-charging-cards',
    //   icon: icons.IdcardOutlined,
    //   breadcrumbs: true
    // },
    // {
    //   id: 'myUsage',
    //   title: 'My Usage',
    //   type: 'item',
    //   url: '/my-usage',
    //   icon: icons.BarChartOutlined,
    //   breadcrumbs: true
    // },
    
    // {
    //   id: 'myBookings',
    //   title: 'My Bookings',
    //   type: 'item',
    //   url: '/my-bookings',
    //   icon: icons.CalendarOutlined,
    //   breadcrumbs: true
    // },
    // {
    //   id: 'helpSupport',
    //   title: 'Help & Support',
    //   type: 'item',
    //   url: '/help-support',
    //   icon: icons.QuestionOutlined,
    //   breadcrumbs: true
    // },
    
  ]
};

export default myAccount;
