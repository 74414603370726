import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Checkbox, Container, Grid, TextField, Typography } from '@mui/material';
import axios from 'axios';
import urls from 'backend_urls';
import { authHeaders } from 'common';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';



const AddAddress = () => {
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessages, setErrorMessages] = useState({});
    const [userCountry, setUserCountry] = useState({});
    const [fullAddress, setFullAddress] = useState(null);
    const [formData, setFormData] = useState({
        tax_number: '',
        zipcode: '',
        address: '',
        city: '',
        state: '',
        address_type: "Billing",
    });
    const [debouncedZipcode, setDebouncedZipcode] = useState('');
    const [timerId, setTimerId] = useState(null);
    const navigate = useNavigate();

    const postalCodeObj = fullAddress?.address_components?.find(component => component.types.includes('postal_code'));
    const stateObj = fullAddress?.address_components?.find(component => component.types.includes('administrative_area_level_1'));
    const cityObj = fullAddress?.address_components?.find(component => component.types.includes('administrative_area_level_2'));
    const address = fullAddress?.formatted_address;

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedZipcode(formData.zipcode);
        }, 1000);

        // Clear the previous timer
        if (timerId) {
            clearTimeout(timerId);
        }

        // Set the timer id for the current timer
        setTimerId(timer);

        return () => {
            clearTimeout(timer);
        };
    }, [formData.zipcode]);

    useEffect(() => {
        const fetchGeolocationDataUsingZipcode = async () => {
            try {
                setIsLoading(true);
                const geolocationData = await fetchGeolocationDatausingZipcode(debouncedZipcode);
                setFullAddress(geolocationData.results[0]);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching geolocation data:', error);
                setIsLoading(false);
            }
        };

        if (debouncedZipcode !== '') {
            fetchGeolocationDataUsingZipcode();
        }
    }, [debouncedZipcode]);


    const saveAddress = async (data) => {
        try {
            const response = await axios.post(`${urls.saveAddress}`, data, { headers: authHeaders() });
            navigate(-1);
            return response.data;
        } catch (error) {
            console.error('save error =======>', error.response.data);
            throw error;
        }
    };


    useEffect(() => {
        setUserCountry(localStorage.getItem('country'))
    }, []);

    const handleChange = async (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const fetchGeolocationDatausingZipcode = async (zipcode) => {
        const apiKey = 'AIzaSyDjzZLs-lsnCDMkQ5uiw6tQi6FffyK7ZZs';
        const url = `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${zipcode}|country:${userCountry}&key=${apiKey}`;

        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Failed to fetch geolocation data');
        }
        return response.json();
    };

    const onSave = async () => {
        setIsLoading(true);
        const errors = {};
        Object.entries(formData).forEach(([key, value]) => {
            const inputElement = document.getElementsByName(key)[0];
            if (inputElement && inputElement.hasAttribute('required') && value.trim() === '') {
                errors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required`;
            }
        });

        setErrorMessages(errors);

        if (Object.keys(errors).length === 0) {
            const mergedData = { ...formData };
            if (fullAddress) {
                const dynamicValues = {
                    zipcode: fullAddress?.address_components?.find(component => component.types.includes('postal_code'))?.long_name,
                    city: fullAddress?.address_components?.find(component => component.types.includes('administrative_area_level_2'))?.long_name,
                    state: fullAddress?.address_components?.find(component => component.types.includes('administrative_area_level_1'))?.long_name,
                    address: fullAddress?.formatted_address
                };
                Object.keys(dynamicValues).forEach(key => {
                    if (!formData[key]) {
                        mergedData[key] = dynamicValues[key];
                    }
                });
            }
            await saveAddress(mergedData);
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    };


    const getCurrentPosition = () => {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    resolve({ latitude: position.coords.latitude, longitude: position.coords.longitude });
                },
                (error) => {
                    reject(error);
                }
            );
        });
    };


    const getLocation = async () => {
        try {
            setIsLoading(true)
            const position = await getCurrentPosition();
            if (position) {
                const geolocationData = await fetchGeolocationData(position.latitude, position.longitude);
                setFullAddress(geolocationData.results[0]);
                setIsLoading(false)
            }
        } catch (error) {
            console.error('Error:', error);
            setIsLoading(false);
        }
    };



    const fetchGeolocationData = async (latitude, longitude) => {
        const apiKey = 'AIzaSyDjzZLs-lsnCDMkQ5uiw6tQi6FffyK7ZZs';
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Failed to fetch geolocation data');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching geolocation data:', error);
            return null;
        }
    };




    const clearFields = () => {
        setFormData({
            tax_number: '',
            zipcode: '',
            address: '',
            city: '',
            state: '',
        });
        setErrorMessages({});
        setFullAddress(null)
    };


    return (
        <>
            <Box >
                <Container maxWidth="lg">
                    <Grid
                        container
                    >
                        <Grid
                            item
                            xs={12} sm={12}
                            display="flex"
                            justifyContent="center"
                            sx={{
                                paddingTop: 2
                            }}
                        >

                            <LoadingButton
                                color="primary"
                                paddingLeft={3}
                                paddingRight={3}
                                onClick={getLocation}
                                loading={isLoading}
                                variant='contained'
                                style={{
                                    width: "100%", display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}
                            >
                                <Typography variant="body1" color="initial" style={{ textAlign: "start", flexGrow: "1", display: "flex", alignItem: "center", justifyContent: "start", color: "white" }}><MyLocationIcon style={{ marginRight: "7px", color: "white" }} /> Use current location</Typography>
                                <Typography variant="body1" color="initial">
                                    <NavigateNextIcon style={{ color: "white" }} />
                                </Typography>
                            </LoadingButton>

                        </Grid>
                    </Grid>
                </Container>
            </Box >
            <Box >
                <Container maxWidth="lg">
                    {fullAddress && fullAddress ? (
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{ marginBotton: "20px", marginTop: "25px" }}>
                                <Typography variant="h6">Enter your address manually</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField name="tax_number" label="ID Number / Tax Number" fullWidth variant="standard" required={userCountry && userCountry == "TH" && true} onChange={handleChange} error={Boolean(errorMessages.tax_number)} helperText={errorMessages.tax_number} InputLabelProps={{ shrink: true }} value={formData.tax_number} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField name="zipcode" label="Zipcode" fullWidth variant="standard"
                                    onChange={(event) => {
                                        const newFullAddress = { ...fullAddress };
                                        const postalCodeComponent = newFullAddress.address_components.find(component => component.types.includes('postal_code'));
                                        if (postalCodeComponent) {
                                            postalCodeComponent.long_name = event.target.value;
                                            setFullAddress(newFullAddress);
                                        }
                                    }}
                                    error={Boolean(errorMessages.zipcode)} helperText={errorMessages.zipcode} InputLabelProps={{ shrink: true }} value={postalCodeObj?.long_name || ''} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField name="state" label="State" fullWidth variant="standard"
                                    onChange={(event) => {
                                        const newFullAddress = { ...fullAddress };
                                        const cityComponent = newFullAddress.address_components.find(component => component.types.includes('administrative_area_level_1'));
                                        if (cityComponent) {
                                            cityComponent.long_name = event.target.value;
                                            setFullAddress(newFullAddress);
                                        }
                                    }}
                                    error={Boolean(errorMessages.state)} helperText={errorMessages.state} InputLabelProps={{ shrink: true }} value={stateObj?.long_name || ''} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField name="city" label="City" fullWidth variant="standard"
                                    onChange={(event) => {
                                        const newFullAddress = { ...fullAddress };
                                        const stateComponent = newFullAddress.address_components.find(component => component.types.includes('administrative_area_level_2'));
                                        if (stateComponent) {
                                            stateComponent.long_name = event.target.value;
                                            setFullAddress(newFullAddress);
                                        }
                                    }}
                                    error={Boolean(errorMessages.city)} helperText={errorMessages.city} InputLabelProps={{ shrink: true }} value={cityObj?.long_name || ''} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField name="address" label="Address" fullWidth variant="standard" onChange={(event) => setFullAddress({ ...fullAddress, formatted_address: event.target.value })} error={Boolean(errorMessages.address)} helperText={errorMessages.address} value={address || ''} InputLabelProps={{ shrink: true }} />
                            </Grid>
                            <Grid item xs={12} sm={6} display="flex" alignItem="center">
                                <Checkbox icon={<CropSquareIcon />} checkedIcon={<CheckBoxIcon />} p={0} onChange={handleChange} error={Boolean(errorMessages.isdefault)} helperText={errorMessages.isdefault} InputLabelProps={{ shrink: true }} value={formData.isdefault} />
                                <Typography variant="body2" display="flex" alignItems="center" style={{ display: "flex", alignItems: "center" }}>
                                    Set as default address
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >

                                <LoadingButton variant="contained" disableElevation loading={isLoading} style={{ width: "100%", padding: "5px 10px", borderRadius: "28px" }} onClick={onSave}> Save and go to invoice</LoadingButton>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >

                                <Button variant="outlined" disableElevation style={{ width: "100%", padding: "5px 10px", borderRadius: "28px" }} onClick={clearFields}> Clear</Button>
                            </Grid>

                        </Grid>
                    ) : (
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{ marginBotton: "20px", marginTop: "25px" }}>
                                <Typography variant="h6">Enter your address manually</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField name="tax_number" label="ID Number / Tax Number" fullWidth variant="standard" required={userCountry && userCountry == "TH" && true} onChange={handleChange} error={Boolean(errorMessages.tax_number)} helperText={errorMessages.tax_number} InputLabelProps={{ shrink: true }} value={formData.tax_number} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField name="zipcode" label="Zipcode" fullWidth variant="standard" onChange={handleChange} error={Boolean(errorMessages.zipcode)} helperText={errorMessages.zipcode} InputLabelProps={{ shrink: true }} value={formData.zipcode} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField name="state" label="State" fullWidth variant="standard" onChange={handleChange} error={Boolean(errorMessages.state)} helperText={errorMessages.state} InputLabelProps={{ shrink: true }} value={formData.state} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField name="city" label="City" fullWidth variant="standard" onChange={handleChange} error={Boolean(errorMessages.city)} helperText={errorMessages.city} InputLabelProps={{ shrink: true }} value={formData.city} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField name="address" label="Address" fullWidth variant="standard" onChange={handleChange} error={Boolean(errorMessages.address)} helperText={errorMessages.address} value={formData.address} InputLabelProps={{ shrink: true }} />
                            </Grid>
                            <Grid item xs={12} sm={6} display="flex" alignItem="center">
                                <Checkbox icon={<CropSquareIcon />} checkedIcon={<CheckBoxIcon />} p={0} onChange={handleChange} error={Boolean(errorMessages.isdefault)} helperText={errorMessages.isdefault} InputLabelProps={{ shrink: true }} value={formData.isdefault} />
                                <Typography variant="body2" display="flex" alignItems="center" style={{ display: "flex", alignItems: "center" }}>
                                    Set as default address
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >

                                <LoadingButton variant="contained" disableElevation loading={isLoading} style={{ width: "100%", padding: "5px 10px", borderRadius: "28px" }} onClick={onSave}> Save and go to invoice</LoadingButton>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >

                                <Button variant="outlined" disableElevation style={{ width: "100%", padding: "5px 10px", borderRadius: "28px" }} onClick={clearFields}> Clear</Button>
                            </Grid>

                        </Grid>
                    )}
                </Container>

            </Box>
        </>
    )
}

export default AddAddress